@import "/src/assets/theme/variables.scss";

// Form Fields
// Drop down
mat-spinner{
    display: flex;
    width: 40px !important;
    height: 40px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg{
        width: 40px !important;
        height: 40px !important;
    }
}

.mat-body, .mat-body-1, .mat-typography{
    line-height: 18px;
}

.bayer-form {
    &.readonly.mat-form-field-appearance-fill .mat-form-field-flex, &.readonly input, .mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{
        background-color: $gray-scale-08 !important;
        color: black;
    }

      &.mandatoryInput{
        border: 1px solid red;
        .mat-form-field-flex
        {
          border: 0;
        }
      }


    input::placeholder,
    textarea::placeholder {
        font-style: italic;
    }

    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-infix {
        width: 130px;
    }

    &.mat-form-field-appearance-fill .mat-form-field-flex {
        padding: 0 0.75em 0 0.75em !important;
        border-radius: 0px !important;
        background-color: white;
    }

    &.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
        transform: translateY(0%) !important;
        position: relative;
        z-index: 3;

        .mat-select-arrow {
            z-index: 2;
            height: 8px;
            width: 15px;
            background-image: url(/assets/icons/arrow-down-white.svg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            color: transparent;
            border: none !important;
        }
    }

    .mat-form-field-wrapper {
        padding-bottom: 0;
        overflow: hidden;
    }

    .mat-form-field-flex {
        height: 50px;
        border: 1px solid $primary-dark-color;

        &:before {
            content: " ";
            position: absolute;
            background-color: $primary-mid-color;
            // border-left: 1px solid $primary-dark-color;
            width: 60px;
            top: 0;
            right: -15px;
            transform: skew(-16deg);
            height: 50px;
            z-index: 1;
        }
    }

    /**Advanced search component dropdown customization*/
    &.filter-form {
        .mat-form-field-flex {
            height: 32px;
            border: 1px solid $primary-dark-color;

            &:before {
                content: " ";
                position: absolute;
                background-color: $primary-mid-color;
                width: 60px;
                top: 0;
                right: -15px;
                transform: skew(-16deg);
                height: 32px;
                z-index: 0;
            }

            .mat-form-field-infix {
                padding: 6px 0 9px 0;
                border-top: 0px;
            }

            .mat-select-value-text {
                font-weight: 100;
            }
        }

        &.mat-form-field-type-mat-select {
            &.mat-form-field-disabled {
                .mat-form-field-flex {
                    background: $bayer-white 0% 0% no-repeat padding-box;
                    border: 0.5px solid $gray-scale-06;

                    &:before {
                        border-left: 0.5px solid $gray-scale-06;
                    }
                }
            }
        }
    }

    &.mat-form-field-type-mat-native-select.mat-form-field-appearance-fill .mat-form-field-infix::after {
        margin-top: -5px !important;
        color: $primary-dark-color;
        margin-right: 5px;
    }

    // .mat-select-arrow {
    //     color: $primary-dark-color;
    // }

    .mat-option {
        padding: 0 11px !important;
    }

    .mat-select-value-text {
        font-weight: 500;
        color: $primary-dark-color;
    }

    &.input .mat-form-field-flex {
        &:before {
            content: none;
        }
    }


    &.date-field {
        .mat-form-field-flex {
            align-items: center;
        }
        .mat-form-field-suffix {
            margin-right: -8px;
        }

        .mat-button-wrapper {
            z-index: 2;
            height: 15px;
            width: 15px;
            background-image: url(/assets/icons/calender-white.svg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            svg {
                display: none !important;
            }
        }

    }

    &.text-area {
        .mat-form-field-flex {
            min-height: 150px;
            height: auto;

            textarea {
                min-height: 150px;
            }

            &:before {
                content: none;
            }
        }
    }

    //Radio button styling
    .mat-radio-label-content {
        padding-bottom: 5px;
    }

    .mat-radio-outer-circle {
        height: 15px !important;
        width: 15px !important;
        // border-color: #707070;
    }

    .mat-radio-inner-circle {
        height: 11px !important;
        width: 11px !important;
        // background-color: #C3BCBC;
    }

    .mat-radio-button .mat-radio-ripple {
        position: absolute;
        left: calc(50% - 22px);
        top: calc(50% - 22px);
    }

    // .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    //     border-color: #707070;
    // }

    .mat-radio-button.mat-accent .mat-radio-inner-circle {
        margin-left: 2.3px;
        margin-top: 2.2px;
    }

    // .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    // .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
    // .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    //     background-color: #C3BCBC;
    // }
    mat-checkbox {
        .mat-checkbox-checkmark-path {
            stroke: $bayer-green !important
        }

        .mat-checkbox-background {
            background-color: transparent !important;
        }

        .mat-checkbox-frame {
            border: 1px solid $bayer-green !important;
            border-radius: 0px;
        }

        &.mat-checkbox-disabled {
            .mat-checkbox-checkmark-path {
                stroke: transparent !important
            }

            .mat-checkbox-background {
                background-color: transparent !important;
            }

            .mat-checkbox-frame {
                border: none !important;
                background-color: $gray-scale-04;
            }
        }
    }

    &.mat-form-field-disabled {
        opacity: .6;

        .mat-form-field-flex {
            border: 1px solid $gray-scale-04;
            //background-color: $gray-scale-04;
            &:before {
                background-color: transparent;
            }
        }
    }


    .mat-chip-remove {
        opacity: 0.3;
        border: none;
        background: transparent;
        margin-left: 2px;

        img {
            width: 8px;
            opacity: 1;
        }
    }

    &.input.keyword{
        .mat-form-field-flex{
            min-height: 63px;
            height: auto;
            overflow: scroll;
        }
    }
}
app-dynamic-rendering .bayer-form{
    &.mat-form-field-disabled {
    opacity: 1;

        .mat-form-field-flex {
            &:before {
                background-color: $primary-mid-color !important;
            }
        }
    }
}

app-dynamic-attributes .bayer-form{
    &.mat-form-field-disabled {
    opacity: 1;

        .mat-form-field-flex {
            background-color: #ededed !important;
            color: black;
        }
    }
}
/**Customized tooltip*/
.custom-tooltip {
    background-color: $bayer-white;
    box-shadow: $box-shadow;
    border-radius: 3px;
    color: $primary-dark-color !important;
    transform: translateY(0px) translateX(0px) !important;
    font-size: 14px;
    font-family: 'HelveticaNeueLight';
    color: $primary-dark-color;
}

app-header .bayer-form{
     &.mat-form-field {
            line-height: 2.125 !important;
        }

     &.mat-form-field-appearance-fill .mat-form-field-flex{
            padding: 0 0.75em 4em 0.75em !important;
    }

     &.mat-form-field-wrapper {
        overflow: visible;
    }
    .mat-form-field-flex {
        height: 56px;
        border-right: 0px;
        &:before {
            background-color: transparent;
        }
    }
     &.mat-form-field-appearance-fill .mat-select-arrow-wrapper .mat-select-arrow {

        background-image: url(/assets/icons/arrow-down.svg);

        margin-top: 4px

    }
}

app-layout .bayer-form {
    .mat-form-field-flex {
        border: 1px solid $gray-scale-04 !important;


        &:before {
            border-left: 1px solid $gray-scale-04 !important;
        }
    }
}

app-dashboard .bayer-form {
    .mat-form-field-flex {
        height: 35px;

        &::before {
            height: 35px;
        }
    }

    .mat-form-field-infix {
        border-top: 5px solid transparent;
    }
}
app-dynamic-rendering .bayer-form   {
    .mat-form-field-flex {
        height: 50px;

        &::before {
            height: 50px;
        }
    }

    .mat-form-field-infix {
        border-top: 0.84375em solid transparent;
    }
}

.cdk-overlay-pane {
    transform: translateX(-10px) !important;

    .mat-select-panel:not([class*=mat-elevation-z]) {
        box-shadow: $box-shadow;
    }

    .mat-select-panel-wrap {
        position: relative;
        top: 26px !important;

        .mat-select-panel {
            border-radius: 0 0 5px 5px !important;
            min-width: calc(100% + 21px) !important;

            .mat-option {
                height: 26px !important;
                padding: 0 13px !important;
                color: $primary-dark-color;
            }
        }
    }
}


// Menu items
.mat-menu-panel {
    min-height: 30px !important;
    max-height: 140px !important;
    margin-top: 10px;
}
#mat-menu-panel-2{
    margin-left: -208px !important;
    margin-top: 18px;
}
#mat-menu-panel-4{
    margin-left: -69px !important;
    margin-top: 18px;
}
#mat-menu-panel-6{
    margin-left: -60px !important;
    margin-top: 18px;
}

.mat-menu-item {
    line-height: 26px !important;
    height: 26px !important;
    padding: 0 9px !important;
    font-size: 16px;
    font-family: 'HelveticaNeueLight';
    color: $primary-dark-color;
}

.mat-menu-content:not(:empty) {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}

//Mat Table

mat-table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    font-family: 'HelveticaNeueMedium';

    .mat-cell {
        min-width: 200px;
        line-height: 18px !important;
    }

    .mat-row,
    .mat-header-row {
        width: fit-content;
        // width: max-content;
        min-width: 100%;
    }

    .mat-sort-header-arrow {
        color: $primary-dark-color;

        .mat-sort-header-stem {
            background-color: transparent !important;
        }

        .mat-sort-header-pointer-left {
            left: -2px;
            width: 8px;
            height: 2px;
        }

        .mat-sort-header-pointer-right {
            width: 8px;
            height: 2px;
            right: -2px
        }
    }

    .mat-header-cell {
        //color: $primary-dark-color;
        //font-weight: 500;
        //font-size: 16px;
        min-width: 200px;

    }

    .mat-header-row {
        min-height: 37px;
        border-bottom: 2px solid $primary-dark-color;
        border-top: 1px solid $primary-dark-color;
    }

    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
        padding-left: 5px !important;
    }

    .mat-footer-cell {
        color: $primary-dark-color;
    }

    mat-row,
    mat-header-row,
    mat-footer-row,
    th.mat-header-cell,
    td.mat-cell,
    td.mat-footer-cell {
        border-bottom-color: $primary-dark-color;
    }


    &.da-table .td.mat-cell{
        padding: 0 7px;
    }
}

app-many-to-many-object-relation, app-advanced-many-to-many-object-relation , .many-to-many-object-relation-dialog-cont, .many-to-one-cont, .dynamic-attribute-cont{
    .mat-header-row {
        height: 36px !important;
        background-color: $gray-scale-08;

        .mat-sort-header-content {
            color: $bayer-dark-blue;
        }
    }

    th.mat-header-cell:first-of-type,
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type {
        padding-left: 10px !important;
    }
}
.dynamic-attribute-cont{
    .mat-table{
        width: 100%;
    }
}

app-content-curator {
    .mat-tab-label{
        color: $gray-scale-04;
    }

    .pad {
        width: 400px;
    }

    .mat-form-field-appearance-fill .mat-form-field-flex .url-input {
        width: 445px;
    }

    tr.mat-header-row {
        background: #EDEDED 0% 0% no-repeat padding-box !important;
        height: 42px;
    }

    th.mat-header-cell {
        color: $primary-dark-color;
        font: normal normal medium 16px/19px Helvetica Neue;
    }

    th.mat-column-action {
        float: right;
    }

    tbody tr {
        &:nth-last-child(1) {
            border-bottom: 2px solid $gray-scale-04;
        }
    }

    .attributestable {
        tr {
            border: none;
            height: 40px;
        }
    }

    .content-type-header {
        width: 190px;
        word-break: break-word;
    }

    .content-checkbox-header {
        width: 150px;
    }

    .attribute-name-header {
        width: 320px;
    }
    .col-name-header{
        width: 180px;
    }

    .attribute-type-header {
        width: 320px;
    }

    .attribute-addaction-header {
        width: 90px;
        text-align: right;
    }

    .attribute-deleteaction-header {
        text-align: right;
    }

    .mat-form-field-appearance-fill.mat-form-field-disabled.input .mat-form-field-flex {
        background-color: $gray-scale-08 !important;
        border: none;

        &:before {
            border-left: none;
        }
    }

    .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
        background-color: $gray-scale-08 !important;
        border: 1px solid #A4A5A5;
        width: 446px !important;

        &:before {
            content: " ";
            position: absolute;
            background-color: transparent;
            border-left: 1px solid $gray-scale-04;
            width: 40px;
            top: 0;
            right: 730px;
            transform: skew(-16deg);
            height: 50px;
            z-index: 0;
        }
    }

    .mat-form-field-appearance-fill.mat-form-field-disabled .mat-select-arrow-wrapper .mat-select-arrow {
        background-image: url(/assets/icons/arrow-down.svg);
    }

    app-document-parser{
        .btn-bayer-primary{
            height: 48px;
            width: 151px;
        }
    }

    app-keyword-generator .bayer-form .mat-form-field-flex {
        height: 48px;
        width: 446px;
        border: 1px solid $primary-dark-color;
    }

    app-dashboard {
        .bayer-form .mat-form-field-flex {
            height: 46px;

            &::before {
                height: 46px;
            }
        }

        .bayer-form.mat-form-field-appearance-fill .mat-select-arrow-wrapper {
            padding-top: 5px;
        }

        .mat-form-field-appearance-fill .mat-form-field-infix {
            padding: 0.55em 0 0.75em 0;
        }
    }

    app-web-scrapper{
        .btn-bayer-primary{
            height: 45px;
            width: 151px;
        }
    }

    app-web-form {
        .bayer-form .mat-form-field-flex {
            border: 1px solid $gray-scale-04;
        }

        .content-checkbox-header {
            width: 100px;
        }

        .attribute-name-header {
            width: auto;
        }

        .attrName {
            width: 180px;

            &.bayer-form .mat-form-field-flex:before {
                background-color: transparent;
                border-left: 1px solid $gray-scale-04;
            }

            &.bayer-form.mat-form-field-appearance-fill .mat-select-arrow-wrapper .mat-select-arrow {
                background-image: url(/assets/icons/arrow-down.svg);
            }
        }

        .disable-url {
            &.bayer-form.mat-form-field-appearance-fill .mat-form-field-flex {
                background-color: $gray-scale-08;
            }
        }


    }
    app-content-tracker{
        .btn-bayer-primary{
            width: 170px;
        }
    }
}

//Mat tabs
.bayer-main-tab {

    background-color: $bayer-white;

    // .mat-tab-body-content {
    //     // min-height: calc(100vh - 150px);
    // }

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: transparent;
    }

    .mat-tab-nav-bar,
    .mat-tab-header {
        border-bottom: none;
    }

    .mat-tab-label {
        box-shadow: 0px 0px 20px #0000001f;
        margin-top: 20px;
        padding: 0 !important;
        min-width: 0;

        .mat-tab-label-content {
            padding: 0 15px 0 24px;
            .tabTitle{
                max-width: 250px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .mat-tab-body-wrapper {
        box-shadow: 0px 0px 20px #0000001f;
        // min-height: calc(100vh - 148px);
    }

    .mat-tab-label-active {
        position: relative;
        opacity: 1 !important;

        .mat-tab-label-content {
            // position: absolute;
            z-index: 2;
            background-color: $bayer-white;
            height: 48px;
            // padding: 0 24px;
            min-width: 160px;
        }
    }
}

.tab{
  &.utility-tab{
  .mat-tab-body-wrapper{
    min-height: 700px;
    }
  }
}

.bayer-form-tab {
    width: 100%;

    .mat-tab-group {
        width: 100%;
    }

    .mat-tab-body-wrapper {
        box-shadow: none;
        min-height: none;

    }

    .mat-tab-label {
        box-shadow: none;
    }

    .mat-tab-labels {
        margin: 0 15px 15px 15px;
        border-bottom: 1px solid $gray-scale-02;
    }


    .mat-tab-label-content {
        color: $primary-dark-color !important;
        background-color: transparent !important;
        opacity: .5;
        font-size: 18px;
        padding: 0 24px 0 0 !important;
        @include transition();

        &:before {
            content: " ";
            position: absolute;
            right: 10px;
            background-image: url(/assets/icons/seprator.svg);
            width: 4px;
            height: 15px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

    }

    .mat-tab-label-active {

        .mat-tab-label-content {
            opacity: 1 !important;
            color: $primary-dark-color;
            font-size: 24px;
            min-width: 0;

            &:before {
                opacity: .5;
            }
        }
    }

    //For removing / in last child
    .mat-tab-list .mat-tab-label:last-of-type {
        .mat-tab-label-content:before {
            content: none !important;
        }

        .mat-tab-label-active {
            .mat-tab-label-content:before {
                content: none !important;
            }

        }
    }

    .mat-ripple-element {
        display: none !important;
    }
}

//Mat Dialog
.mat-dialog-container {
    padding: 0px !important;
    border-radius: 0 !important;
    overflow: hidden !important;
}

.mat-dialog-content {
    max-height: 1000vh !important;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.mat-dialog-content::-webkit-scrollbar {
    display: none;
}

.breadcrumb {
    background-color: white;
}

.mat-dialog-actions {
    justify-content: initial;
    position: fixed;
    bottom: 20px;
    padding: 0px !important;
}
.multimedia-tree{
    .node-drop-slot{
        height:0 !important;
    }
}

.back-to-top {
    position: fixed;
    bottom: -70px;
    right: 0;
    width: 40px;
    height: 44px;
    z-index: 2000;
    background-color: $secondary-color;
    @include transition();
    cursor: pointer;

    &:hover {
        background-color: $secondary-dark-color;

        &::before {
            background-color: $secondary-dark-color;
        }
    }

    &:before {
        content: " ";
        background-color: $secondary-color;
        position: absolute;
        width: 20px;
        top: 0px;
        left: -10px;
        transform: skew(-10deg);
        height: 50px;
        z-index: -1;
        @include transition();

    }
    .text-cont{
        margin-right: 7px;
    }

    img {
        width: 12px;
        margin-right: 7px;
        @include transition();

    }

    &.active {
        bottom: 0;
    }
}
// Progress Bar

.menu-items-cont .mat-progress-bar{
    margin-left: -20px;
    height: 2px;
    width: calc(100% + 20px);
  }
//Snack bar
.mat-snack-bar-container{
    background:$bayer-white ;
    overflow: hidden;
    border-radius: 0px !important;
}

.spinner .mat-progress-spinner circle {
    stroke: white;
}

.tree-node-level-1{
    margin-bottom: 10px;
}

//Mat Pseudo check-boxes

.mat-primary .mat-pseudo-checkbox-checked{
    background-color:transparent !important;
    border-color: $bayer-green!important;
}
.mat-pseudo-checkbox {
        border:1px solid $gray-scale-04!important;
        &::after{
        color: $bayer-green !important;
    }
}

// Mat autocomplete

#mat-autocomplete-0{
    left: 10px !important;
}

