// Bayer colors
// Primary Colors
$primary-color:#0091DF;
$primary-mid-color:#00617F;
$primary-dark-color:#10384F;
$primary-mid-light-color:#01617F;
$primary-mid-dark-color:#38849B;
// Secondary Colors
$secondary-color:#624963;
$secondary-dark-color:#443247;
// Bayer Colors
$bayer-red: #FF3162;
$bayer-dark-red: #D30F4B;
$bayer-green: #65B511;
$bayer-white:white;
$bayer-black:black;
$bayer-dark-blue:#11374F;
$bayer-blue:#22789F;
// GrayScale
$gray-scale-01:#94a5b0;
$gray-scale-02:#bfbfbf;
$gray-scale-03:#e2e0e0;
$gray-scale-04:#a4a5a5;
$gray-scale-05:#f8f8f8;
$gray-scale-06:#c3bcbc;
$gray-scale-07:#5c5c5c;
$gray-scale-08:#ededed;
$gray-scale-09:#f8f8f8;
$gray-scale-10:#808080;
$gray-scale-11:#F0F0F0;
$gray-scale-12:#eae8e8;

//Fonts Imports
@font-face {
    font-family: 'HelveticaNeueBold';
    src: url(../fonts/HelveticaNeueLTPro-Bd.otf);
    // src: url(../fonts/HelveticaNeueBold.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNeueLight';
    src: url(../fonts/HelveticaNeueLTPro-Lt.otf);
    // src: url(../fonts/HelveticaNeueLight.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNeueMedium';
    src: url(../fonts/HelveticaNeueLTPro-Md.otf);
    // src: url(../fonts/HelveticaNeueMedium.ttf) format('truetype');
}
@font-face {
    font-family: 'HelveticaNeueRegular';
    src: url(../fonts/HelveticaNeue.ttf) format('truetype');
}


// Header variables
$header-height:110px;
$menu-width : 330px;
$box-shadow: 0px 3px 20px #0000002B;
$box-shadow-menu: 0px 3px 20px #0000002B;
$box-shadow-tab: 0px 3px 20px #0000002B;

@mixin transition() {
    -webkit-transition: all .3s ease-out, right .2s ease-out;
    -moz-transition: all .3s ease-out, right .2s ease-out;
    -ms-transition: all .3s ease-out, right .2s ease-out;
    -o-transition: all .3s ease-out, right .2s ease-out;
    transition: all .3s ease-out, right .2s ease-out
}

@mixin dashboard-cont($color, $icon) {
    border: 1px solid $color;
    color: $color;
    padding: 10px 10px;
    border-radius: 0px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    min-height: 75px;
    background-color: transparent;
    @include transition();

    .img-cont {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: #ffffff90;
        background-image: url($icon);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 25px;
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 15px;
    }

    &:before,
    &:after {
        content: " ";
        position: absolute;
        z-index: -1;
        height: 100%;
        width: 100%;
        top: 0;
        background-color: $color;
        @include transition();
    }

    &:before {
        transform: skewX(-23deg) translateX(-100%);
        left: 23px;
    }

    &:after {
        transform: skewX(-10deg) translateX(-100%);
        left: 28px;
        opacity: .5;
    }

    &:hover {
        // background-color: $color;
        color: white;

        &:before,
        &:after {
            width: 200%;
            left: 150%;
        }
    }

    &.active {
        background-color: $color;
        color: white;
    }
}


@mixin progress-bar($color) {
    background-color: $color;
    padding: 10px;
    margin: 0px 3px;
    color: white;
    transform: skew(-12deg);
    width: 204px;
    height: 39px;
}

@mixin icon($url, $hovUrl, $height, $width) {
    background-image: url($url);
    height: $height;
    width: $width;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    cursor: pointer;
    @include transition();

    &:hover,
    &:active {
        background-image: url($hovUrl);
    }

    &:disabled {
        cursor: default;
        opacity: .7;
    }
}